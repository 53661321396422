<template>
  <uiTf
    v-bind='$attrs'
    :ct="ct"    
    :label="label"
    :styleComp="styleComp"
    :required="required"
    :type="showPass? 'text' : 'password'"
    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"   
    @onAppend="showPass = !showPass">
  </uiTf>
</template>



<script>

    export default {
        props: {
            ct: { type: [Array, Object], default: null },
            label: { type: String, default: "" },
            styleComp: { type: String, default: "" },
            required: { type: Boolean, default: false }            
        },

        data() {
            return {
                showPass:false
            }
        },
    }

</script>



